import * as Sentry from "@sentry/vue";
import JSZip from "jszip";
import timezones from "@/helpers/timezones";

export async function downloadAsZip(artifactUrls, sessionOrProjectName="", folderName, fileTitles = []) {
	const zip = new JSZip();
	const datePart = timezones.formatDate(new Date().toISOString(), "YYYY-MM-DD_HHmmss");
	sessionOrProjectName = sessionOrProjectName.replace(/\s+/g, '-');
	sessionOrProjectName = sessionOrProjectName.replace(/[^a-zA-Z0-9-_]/g, "");
	folderName = (folderName + "_" + sessionOrProjectName.substring(0, 25) + "_" + datePart).toLowerCase();
	const folder = zip.folder(folderName);

	const downloadFile = async (url, fileName) => {
		const separator = url.includes("?") ? "&" : "?";
		const fileUrl = url + separator + "timestamp=" + new Date().getTime();
		if (!fileName) {
			fileName = fileUrl.split("?")[0].substring(fileUrl.lastIndexOf("/") + 1);
			fileName = fileName.replace("snapshot", "file");
		}

		try {
			const response = await fetch(fileUrl);
			if (!response.ok) {
				throw new Error(`Failed to fetch ${fileUrl}: ${response.statusText}`);
			}
			const blob = await response.blob();
			folder.file(fileName, blob);
		} catch (error) {
			console.error(`Failed to download file from ${fileUrl}:`, error);
			Sentry.captureException(error, {
				tags: {
					method: "downloadAsZip",
					file: "download.js",
				},
				extra: {
					fileUrl: fileUrl,
				},
			});
		}
	};

	const downloadPromises = artifactUrls.map((url, index) => {
		if (fileTitles.length === 0) {
			return downloadFile(url, null); //Snapshots
		}
		//Documents
		const fileName = fileTitles[index];
		return downloadFile(url, fileName);
	});

	await Promise.all(downloadPromises);

	zip.generateAsync({ type: "blob" }).then((content) => {
		const link = document.createElement("a");
		link.href = URL.createObjectURL(content);
		link.download = `${folderName}.zip`;
		link.click();
	});
}
