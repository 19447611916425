<template>
	<div class="d-flex" :class="{ flex: buttonFabTextPrefix }">
		<v-menu
			@input="menuInput"
			v-model="menu"
			class="action-menu"
			content-class="vue-tel-input-menu new-conversation"
			:close-on-content-click="false"
			:close-on-click="closeOnClick"
			:min-width="maxWidth"
			:max-width="maxWidth"
			nudge-width="250"
			nudge-left="125"
			:nudge-top="buttonFabTextPrefix ? 600 : 100"
			offset-y
			top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-if="buttonFab"
					:id="`${buttonId ? buttonId : 'action-fab'}`"
					:class="`action-fab-${action}`"
					v-bind="attrs"
					v-on="on"
					fab
					medium
					@click="selectAction">
					<v-icon class="action-button-icon">{{ buttonIcon }}</v-icon>
				</v-btn>
				<div
					class="d-flex flex align-center justify-end"
					v-else-if="buttonFabTextPrefix"
					v-bind="attrs"
					v-on="on">
					<span class="fab-action-header flex" :style="fontSizeStyle">{{ buttonText }}</span>
					<v-btn
						:id="`${buttonId ? buttonId : 'action-fab'}`"
						:class="`action-fab-${action}`"
						v-bind="attrs"
						v-on="on"
						fab
						medium
						@click="selectAction">
						<v-icon class="action-button-icon" :style="fontSizeStyle">{{ buttonIcon }}</v-icon>
					</v-btn>
				</div>
				<v-btn
					v-else
					:id="`${buttonId ? buttonId : 'action-button'}`"
					v-bind="attrs"
					v-on="on"
					:fab="buttonFab"
					:rounded="buttonRounded"
					:class="['responsive-action-button']"
					:height="$vuetify.breakpoint.smAndDown ? 'auto' : ''"
					:min-height="48"
					x-large
					@click="selectAction">
					<v-icon v-if="buttonIcon" class="action-button-icon mr-2">{{ buttonIcon }}</v-icon>
					<span class="action-button-text">{{ buttonText }}</span>
				</v-btn>
			</template>

			<ActionDialog
				v-if="menu"
				ref="actionDialog"
				:project-id="projectId"
				:actioning-text-override="actioningTextOverride"
				:show-header="true"
				:tab-action="action"
				@close-menu="closeMenu()" />
		</v-menu>
	</div>
</template>

<script>
	import ActionDialog from "./ActionDialog.vue";
	import { EventBus } from "../helpers/eventBus";
	import { hasFreeTrialEnded } from "../helpers/hasFreeTrialEnded.js";
	import api from "../api";
	import DeviceUtility from "@/helpers/device";

	export default {
		components: {
			ActionDialog,
		},
		props: {
			buttonId: String,
			buttonType: String,
			buttonIcon: String,
			buttonText: String,
			action: String,
			projectId: String,
		},
		data() {
			return {
				buttonRounded: false,
				buttonFab: false,
				buttonFabTextPrefix: false,
				vueTelProps: window.VueTelProps,
				starting: false,
				validPhone: false,
				autoSetName: false,
				autoSetNameValue: "",
				menu: false,
				phone: "",
				name: "",
				meetingUrl: "",
				actioningTextOverride: "",
				closeOnClick: true,
				organization: null,
				hasFreeTrialEnded: false,
				fontSizeStyle: null,
			};
		},
		async mounted() {
			if (this.buttonType == "rounded") {
				this.buttonRounded = true;
			} else if (this.buttonType == "fab") {
				this.buttonFab = true;
			} else if (this.buttonType == "textFab") {
				this.buttonFabTextPrefix = true;
			}

			if (this.action === "schedule") {
				EventBus.$on("DisableCloseOnClick", () => {
					this.closeOnClick = false;
				});

				EventBus.$on("EnableCloseOnClick", () => {
					this.closeOnClick = true;
				});
			}
			this.organization = this.$root.$organization;
			if (!this.organization) {
				const me = await api.getMe();
				this.organization = me.organization;
			}
			this.hasFreeTrialEnded = await hasFreeTrialEnded(this.organization);
			this.fontSizeStyle = { fontSize: this.windowWidth / 900 + "rem" };
		},
		methods: {
			selectAction() {
				if (this.hasFreeTrialEnded) {
					this.$root.$freeTrialEndedDialog.open(this.organization);
				} else {
					if (!DeviceUtility.isMobile) {
						this.menu = true;
						this.$refs.actionDialog?.reset();
					} else {
						this.$router.push({
							name: this.action,
							query: {
								from: "projects/" + this.projectId,
								projectId: this.projectId,
							},
						});
					}
				}
			},
			menuInput(value) {
				if (this.hasFreeTrialEnded) {
					this.menu = false;
				}
				this.selectAction();
			},
			closeMenu() {
				this.menu = false;
				this.$emit("close-parent-menu");
			},
		},
		computed: {
			maxWidth() {
				return this.$vuetify.breakpoint.smAndDown ? "100%" : 425;
			},
			alignLeft() {
				return this.$vuetify.breakpoint.smAndDown ? true : false;
			},
		},
	};
</script>
<style scoped>
	.responsive-action-button {
		width: 100%;
		white-space: normal;
		height: auto !important;
		padding: 8px 16px;
	}
	.fab-action-header {
		text-align: right;
		font-weight: 700;
		margin-right: 16px;
		flex: 1;
	}
	.action-button-text {
		word-break: break-word;
		font-size: 1rem;
	}

	.action-button-icon {
		flex-shrink: 0;
	}

	.action-menu {
		z-index: 101;
	}
</style>
