<template>
	<v-dialog transition="dialog-bottom-transition" max-width="400" v-model="dialog">
		<v-card>
			<v-toolbar color="primary" dark>New Field</v-toolbar>
			<v-card-text class="justify-center mt-2">
				<v-form class="tag-form" ref="form">
					<v-text-field
						outlined
						label="Name"
						color="primary"
						v-model.trim="formField.name"
						:rules="[
							(value) => (!!value && value?.trim().length > 0) || 'Name is required.',
							(value) => !value || value.length <= 150 || 'Name must not exceed 150 characters.',
						]"></v-text-field>
					<v-select
						outlined
						append-icon="icon-dropdown-down"
						:items="fieldTypes"
						item-text="display"
						item-value="value"
						v-model="formField.type"
						label="Type"
						menu-props="auto"
						:rules="[(value) => !!value || 'Type is required.']" />
					<v-checkbox v-model="formField.required" label="Required field for the user"></v-checkbox>
				</v-form>
			</v-card-text>
			<v-card-actions class="justify-center">
				<v-btn large rounded elevation="0" @click="cancel" id="cancel-field"> Back</v-btn>
				<v-btn
					large
					rounded
					elevation="0"
					color="primary"
					:disabled="!formField.name || !formField.type"
					@click.close="addField"
					id="save-field"
					>Save</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script lang="ts" setup>
	import { onMounted, ref } from "vue";
	import { FieldTypeDisplay } from "@/types/forms/FieldTypeDisplay";
	import { FieldValidationType } from "@/types/forms/FieldValidationType";
	import { FormField } from "@/types/forms/FormField";

	const form = ref();
	const dialog = ref<boolean>(false);
	const formField = ref<FormField>({ required: true, canBeDeleted: true } as FormField);
	const fieldTypes =
		Object.keys(FieldTypeDisplay)
			.map((x)  =>  {
				return {"display" : FieldTypeDisplay[x as keyof typeof FieldTypeDisplay],
					     "value" : x
			}});
	const props = defineProps<{
		dialogOpen: boolean;
	}>();

	defineExpose({
		open,
	});

	const emit = defineEmits<{
		(event: "addField", field: FormField): void;
	}>();

	onMounted(() => {
		dialog.value = props.dialogOpen;
	});

	function open() {
		dialog.value = true;
	}

	function addField() {
		if (formField.value.required) {
			formField.value.FieldValidators = [
				{
					errorMessageTpl: `${formField.value.name} is required.`,
					type: FieldValidationType.Required,
					customValidator: "",
					value: undefined,
				},
			];
		}
		emit("addField", formField.value);
		cancel();
	}

	function cancel() {
		formField.value = { required: true, canBeDeleted: true } as FormField;
		dialog.value = false;
		form.value.resetValidation();
	}
</script>
<style lang="scss"></style>
